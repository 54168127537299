// hover-box styles
.hover-box {
    display: block;
    transition: background-color 0.2s ease-out;
    transform-style: preserve-3d;

    cursor: pointer;

    @at-root {
        a#{&}, a:hover#{&}, a:focus#{&}, a:active#{&} {
            color: $brand-color-white;
        }
    }

    .hover-wrap {
        position: relative;
        min-height: 370px;
        overflow: hidden;
        width: 100%;
        margin-bottom: 45px;

        transition: min-height 0.3s ease-in-out;
        .centerBox{
            h2{
                font-family: 'Andes W04', 'Helvetica Neue', Helvetica, Arial, sans-serif;
            }
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    // Test fixed height
    &.fixed-height {
        .hover-wrap {
            min-height: 400px;
        }
    }

    .default, .active {
        @include vertical-align($position: absolute);

        text-align: center;

        transition: transform 0.3s ease-in-out, opacity 0.2s ease-in-out;
    }

    .default {
        display: block;
        opacity: 1;
        height: 370px;
        left: 0px;
        right: 0px;
        margin-left: 30px;
        margin-right: 30px;
        p {
          font-size: 20px;
        }
    }

    .active {
        //display: none;
        transform: translateY(150%);
        opacity: 0;
    }
}
