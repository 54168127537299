// top-menu styles
.top-menu.navbar-default {
    min-height: $top-menu-height;
    min-height: inherit;
    margin-bottom: 0px;
    border: none;
    background-color: $brand-color-white;

    .navbar-header{
        margin-left:0px;
        margin-right:0px;
    }

    #homeButton{
                top: 5px;
                position: absolute;
                width: 35px;
                height: auto;
                cursor: pointer;
                left: 20px;
            }

    .container-fluid {
        max-width: $section-wrap-max-width;
        padding-left: 0px;
        padding-right: 0px;
    }

    .navbar-nav {
        margin-left: 0px;
        li {
            float: none;
            display: block;
            text-transform: uppercase;
            
            a {
                font-size: 1.2em;
                padding-bottom: 8px;
                color: $menu-text-color-default;

                &.selected {
                    font-weight: bolder;
                    pointer-events: none;
                }

                &:hover {
                    color: $menu-text-color-hover;
                }
            }
        }
        width: 100%;
        text-align: center;
    }
}

@media (min-width: 768px){
    .navbar-header{
     display:none;
    }
    .navbar-nav {
        li {
            float: none;
            display: inline-block !important;
            border-style: solid;
            border-top: thick $brand-color-dark;
        }
    }
}
