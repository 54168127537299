// section styles

.page-section {
    padding: 60px 0;

    &.vertical-align {
        display: flex;

        .section-wrap {
            display: flex;
            align-items: center;

            .vertical-top {
                align-self: flex-start;
            }

            .vertical-bottom {
                align-self: flex-end;
            }
        }
    }

    &.header {
        background-color: $brand-color-white;
        position: relative;
        height: 600px;
        #dimensionsLogo{
            width: 472px;
            height: 203.3px;
        }

        .section-wrap {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            background-repeat: no-repeat;
            background-size: cover;
            background-position: left bottom;

            .header-text {
                position: absolute;
                

                &.right {
                    bottom: 37px;
                    right: 57px;
                    text-align: right;
                }

                &.left {
                    bottom: 37px;
                    left: 57px;
                    text-align: left;
                }

                h1, h2, h3, h4 {
                    font-size: 2.0em;
                    margin-top: 10px;
                    margin-bottom: 5px;
                    text-transform: uppercase;
                    color: $text-color-highlight-alternative;
                }

                .tagline {
                    font-size: 1.3em;
                    text-align: center;
                }
            }
        }
    }

    &.footer {
        padding-top: 10px;
        padding-bottom: 5px;
        background-color: $brand-color-black;
        position: relative;
        p{
            color: $brand-color-light-gray;
            text-align: right;
            margin: 0px;
            font-size: 14.47px;
        }
    }

    &.full-height {
        min-height: 100vh;
    }

    &.alternative {
        background-color: $background-color-alternative;
    }

    &.tight {
        padding: 0;
        .centerBox{
            .text-highlight{
                margin: 0px;
                font-size: 24.33px;
            }
            p{
                margin-top: 10px;
                font-size: 20px;
            }
        }
        .moreButton{
            position: absolute;
            bottom: 20px;            
            left: 0px;
            right: 0px;
            margin-left: auto;
            margin-right: auto;
            padding-top: 10px;
            padding-bottom: 8px;
        }
        .moreButton:hover{
            color: $brand-color-orange;
        }
    }

    &.highlight, &.lead {
        color: $text-color-inverted;
        margin-bottom: 0;

        p{
         max-width: 720px;
         text-align: center;
         margin-left: auto;
         margin-right: auto;
         position: relative;
        }

        .quoteText{
            width: 33.87px;
            height: 30.54px;
            position: relative;
            top: -15px;
            left: -10px;
        }
        .quoteReversed{
            bottom: -10px;
            left: 15px;
            width: 33.87px;
            height: 30.54px;
            position: relative;
            -moz-transform: scale(-1, -1);
            -webkit-transform: scale(-1, -1);
            -o-transform: scale(-1, -1);
            -ms-transform: scale(-1, -1);
            transform: scale(-1, -1);
        }

        &.alternative {
            background-color: $background-color-highlight-alternative;
        }
    }

    &.quote{
        background-color: $brand-color-dark-gray;
        color: $brand-color-white;
    }

    &.lead {
        padding: 60px 0;
        text-align: center;
        &.slim {
            padding: 60px 0;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    &.article {        
        h2 {
            text-transform: uppercase;
            font-size: 24.33px;
        }

        .subtitle {
            font-size: 20px;
        }
    }

    &.whatWeStandFor{
        padding: 0px;
        .rowPanel{
            background: $brand-color-black;
            color: $brand-color-light-gray;
        }
        #accordation{
            .rowPanel:nth-child(4n+1){
             background: $brand-color-dark-gray;
             color: $brand-color-light-gray;
            }
        }
        

        .moreButton{
            position: relative;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 20px;
            cursor: pointer;
            margin-left: auto;
            margin-right: auto;
        }
        .moreButton:hover{
            color: $brand-color-orange;
        }
        .container{
            align-items: center;
            flex-wrap: wrap;
            display: flex;
        }
        .exapandablePanel{
            display: flex;
            position: relative;
            cursor: pointer;
            padding-left: 0px;
            padding-right: 0px;
            h2{
                font-size: 24.33px;
                font-family: 'Andes W04', 'Helvetica Neue', Helvetica, Arial, sans-serif;
            }
            p{
                font-size: 20px;
            }
            .column{
                flex-direction: column;
            }
            .iconBox{
                flex: 1 1 50px;
                justify-content: center;
                position: relative;
                .boxesIcon{
                    height: 78px;
                    margin-top: auto;
                    margin-bottom: auto;
                    top: 0px;
                    bottom: 0px;
                    position: absolute;
                }
            }
            .textBox{
                flex: 1 1 100%;
                padding-right: 0px;
                padding-left: 40px;
                padding-top: 20px;
                padding-bottom: 20px;

            }

            .expandButtonBox{
                flex: 1 1 50px;
                display: block;
                background-color: $brand-color-orange;
                position: relative;
            }
            
        }
        .expandedPanel{
            h3{
                font-size: 24.33px;
                font-family: 'Andes W04', 'Helvetica Neue', Helvetica, Arial, sans-serif;
            }
            p{
                font-size: 20px;
            }
            .block{
                padding-top: 20px;
                padding-bottom: 20px;
                padding-left: 20px;
                padding-right: 20px
            }
        }

        .restricted{
            padding-left: 0px;
            padding-right: 0px;
            padding-top: 50px;
            padding-bottom: 50px;
            p{
             color: $brand-color-dark-gray;
            }
        }
    }

    &.privacyPolicy {
        background-color: #cdcdcd;
        color: $brand-color-black;
        padding-left: 15%;
        padding-right: 15%;

        a {
            color: $brand-color-orange;
        }

        h2 {
            font-size: 18px;
        }

        p {
            font-size: 14px;
        }
    }

    &.ourApproach {
        color: $brand-color-black;
        h1{
         font-size: 30px;
         color: $brand-color-orange;
         font-family: 'Andes W04', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        }
    }

    &.infoPanel{
        h1{
            font-family: 'Andes W04', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        }
        h1,p{
            font-size: 24.33px;
        }
        background-color: $brand-color-dark-gray;
        .iconTextBox{
            padding-top: 20px;
            padding-bottom: 20px;
            flex-direction: row;
            position: relative;
            display: flex;
            .columnIcon{
                flex: 1 1 105px;
                position: relative;
                .iconImage{
                    width: 80px;
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    margin-top: auto;
                    margin-bottom: auto;
                    padding-left: 5px;
                }
            }
            .columnText{
                flex: 1 1 100%;
                padding-left: 15px;
                padding-right: 15px;
                p{
                    font-size: 20px;
                }
            } 
        }
    }

    &.whatWeDo{
        padding: 0px;
        .rowPanel{
            background: $brand-color-black;
            color: $brand-color-light-gray;
        }
        #accordation{
            .rowPanel:nth-child(4n+1){
             background: $brand-color-dark-gray;
             color: $brand-color-light-gray;
            }
        }
        .container{
            align-items: center;
            flex-wrap: wrap;
            display: flex;
        }
        .moreButton{
            position: relative;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 20px;
            cursor: pointer;
            margin-left: auto;
            margin-right: auto;
        }
        .moreButton:hover{
            color: $brand-color-orange;
        }
        .exapandablePanel{
            display: flex;
            position: relative;
            cursor: pointer;
            padding-left: 0px;
            padding-right: 0px;
            h2{
                font-size: 24.33px;
                font-family: 'Andes W04', 'Helvetica Neue', Helvetica, Arial, sans-serif;
            }
            p{
                font-size: 20px;
            }
            .column{
                flex-direction: column;
            }
            .iconBox{
                flex: 1 1 50px;
                justify-content: center;
                position: relative;
                .boxesIcon{
                    height: 78px;
                    margin-top: auto;
                    margin-bottom: auto;
                    top: 0px;
                    bottom: 0px;
                    position: absolute;
                }
            }
            .textBox{
                flex: 1 1 100%;
                padding-left: 50px;
                padding-right: 20px;
                padding-top: 20px;
                padding-bottom: 20px;

            }
            .expandButtonBox{
                flex: 1 1 50px;
                display: block;
                background-color: $brand-color-orange;
                position: relative;
                .fa-chevron-down{
                    width: 100%;
                    height: 30px;
                    color: $brand-color-white;
                    margin-top: auto;
                    margin-bottom: auto;
                    top: 0px;
                    bottom: 0px;
                    position: absolute;
                }
            }
        }
        .expandedPanel{
            padding-top: 50px;
            padding-bottom: 50px;
            display: block;
            h3{
                margin-bottom: 0px;
            }
            p{
                color: $brand-color-dark-gray;
                font-size: 20px;
            }
                .post{
                    position: relative;
                    display: flex;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    flex-direction: row;
                    .sideIcon{
                        flex: 1 1 60px;
                        position: relative;
                        .boxesIcon{
                            width: 25px;
                            margin-top: auto;
                            margin-bottom: auto;
                            top: 0px;
                            position: absolute;
                            left: 0px;
                            right: 0px;
                            margin: auto;
                        }
                    }

                    .sideText{
                        flex: 1 1 100%;
                        color: $brand-color-dark-gray;
                        padding-left: 20px;
                        padding-right: 20px;
                        p{
                            font-size: 20px;
                        }
                    }
                }
                .tech{
                    color: $brand-color-black;
                    margin-top: 30px;
                    margin-left: auto;
                    margin-right: auto;
                    align-items: center;
                    flex-wrap: wrap;
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    position: relative;

                    .techButton{
                        background-color: $brand-color-white;
                        -moz-border-radius: 10px;
                        -webkit-border-radius: 10px;
                        border: 2px solid $brand-color-light-gray;
                        width: 150px;
                        height: 45px;
                        text-align: center;
                        padding-top: 9px;
                        padding-bottom: 5px;
                        cursor: pointer;
                        margin-right: 20px;
                        float: left;
                        margin-top: 20px;
                        justify-content: center;
                        position: relative;
                        flex-direction: column;
                    }
                    span{
                        font-size: 20px;
                        max-width: 500px;
                        margin-top: 20px;
                        justify-content: center;
                        position: relative;
                        flex-direction: column;
                    }
                    a{
                        color: $brand-color-dark-gray;
                    }
                }
            }
        .restricted{
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    &.meetTheTeam{
        padding-top: 0px;
        padding-bottom: 0px;
        h1{
            font-size: 24.33px;
        }
        h4{
            color: inherit;
            font-size: 23px;
        }

        p{
            font-size: 20px;
        }

        a:link, a:visited,a:hover, a:active{
            color: inherit;
        }

        .row:nth-child(odd){
            color: $brand-color-light-gray;
            background: $brand-color-dark-gray;
        }
        .row:nth-child(even){
            color: $brand-color-dark-gray;
            background: $brand-color-white;
            p{
                color: $brand-color-dark-gray;
            }
            .moreButton:hover{
                color: $brand-color-orange;
            }
        }
        .linkedinLogo{
            width: 30px;
            left: 0px;
            top: -2px;
            position: relative;
            margin-right: 10px;
        }
        .moreButton{
            position: relative;
            text-align: left;
            margin-top: 20px;
            margin-bottom: 20px;
            cursor: pointer;
        }
        .moreButton:hover{
            color: $brand-color-orange;
        }
        .container{
            padding-top: 30px;
            padding-bottom: 30px;
            cursor: pointer;
        }
    }

    &.contactUs{
        padding-top: 0px;
        padding-bottom: 0px;
        .mapInfo{
            padding-top: 0px;
            padding-bottom: 0px;
            .container-fluid{
                padding-left: 0px;
                padding-right: 0px;
            }
            background: $brand-color-dark-gray;
            #map{
             width: 100%;
             height: 300px;
             #overlayMap{
                color: $brand-color-black;
                p,b{
                    font-size: 20px;
                    text-align: left;
                    line-height: 30px;
                    color: $brand-color-black;
                }
              
             }
            }
        }
        .info{
            background: $brand-color-dark-gray;
            padding-top: 60px;
            padding-bottom: 60px;
            p,a{
                color: $brand-color-white;
                text-align: left;
                max-width: 620px;
                position: relative;
                margin-left: auto;
                margin-right: auto;
                font-size: 20px;
            }
        }
        
        .highlight{
         color: $brand-color-orange;
        }
        a:hover{
            color: $brand-color-orange;
        }
    }

    &.getInTouch{
        background-color: $brand-color-white;
        .centeredBlock{
            cursor: pointer;
            width: 100%;
            max-width: 760px;
            text-align: left;
            position: relative;
            margin-left: auto;
            margin-right: auto;
            color: $brand-color-black;
            h1{
                font-size: 32px;
                margin: 0px;
                font-family: 'Andes W04', 'Helvetica Neue', Helvetica, Arial, sans-serif;
            }
            p{
                margin-top: 10px;
            }
            .contactUsButton{
                position: relative;
                margin-top: 30px;
                color: black;
                margin-left: auto;
                margin-right: auto;
                top: 0px;
                bottom: 0px;
                left: 0px;
                right: 0px;
                height: 50px;
                width: 180px;
            }
            .contactUsButton:hover{
                color: $brand-color-orange;
            }
            .verticalAlignement{
                height: 100%;
                position: relative;
            }
        }
    }

    .section-wrap {
        position: relative;
        margin: 0 auto;

        & > .title {
            text-align: right;
            padding-right: 40px;

            & > h2 {
                margin: 10px 30px 10px 0;
            }

            & > p {
                font-size: 1.3em;
                margin: 10px 30px 10px 0;
            }

            &:after{
                content: "";
                display: block;
                position: absolute;
                right:25px;
                top: -30px;
                bottom: -30px;

                $title-line-width: 4px;

                width: $title-line-width;
                background-color: #9f9f9f;
                border-radius: $title-line-width / 2;

            }
        }

        .maxWidthBlock{
            max-width: $section-wrap-max-width;
            margin-left: auto;
            margin-right: auto;
            .hover-wrap:nth-child(n){
                background-color: $brand-color-dark-gray;
            }
            .hover-box:nth-child(2){
                .hover-wrap{
                    background-color: $brand-color-black;
                }
            }
        }
    }

    .rowPanel,.container:active, select:hover {
      outline: none;
    }

    .rowPanel,.container:active, select:hover {
      outline-color: none;
    }
}

@media screen and (max-width: 768px) {

    .page-section {

        .header-text.right{
            right: 0 !important;
            left: 0;
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            
            #dimensionsLogo{
                width: 300px;
                margin-left: auto;
                margin-right: auto;
                position: relative;
                display: block;
                height: 134px;
            }
            .tagline{
                text-align: center;
                width: 100%;
                font-size: 15px !important;
            }
        }
        

        &.vertical-align {
            display: block;

            .section-wrap {
                display: block;
            }
        }

        .section-wrap {
            .title {
                text-align: left;
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }
    }
}


@media (max-width: 768px){
        .getInTouch{
            p{
                text-align: center !important;
            }
        }
}
