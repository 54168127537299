@fa-font-path:   "../font";


// Normalize Styles
@import 'node_modules/normalize.css/normalize';
@import 'node_modules/bootstrap-sass/assets/stylesheets/_bootstrap';
@import 'node_modules/font-awesome/scss/font-awesome';

/***
 *
 * Variables
 *
 ***/

$section-wrap-max-width: 1280px;
$top-menu-height: 50px;

/***
 * Colors
 ***/

$brand-color-dark: #cdcdcd;
$brand-color-black: #000000;
$brand-color-white: #FFFFFF;
$brand-color-gray: #cdcdcd;
$brand-color-orange: #ee7f00;
$brand-color-light-gray: #cdcdcd;
$brand-color-dark-gray: #383838;
$brand-color-light: #F3F3F3;
$brand-color-light-alternative: #EAF2F2;
$brand-color-highlight: $brand-color-dark;
$brand-color-highlight-alternative: #ee7f00;

 // Top menu
$menu-text-color-default: $brand-color-black;
$menu-text-color-highlight: $brand-color-highlight-alternative;
$menu-text-color-hover: $menu-text-color-highlight;
$menu-background-color: $brand-color-light-gray;
$menu-buttons-background-color: $brand-color-light-gray;
$menu-border-color: $menu-background-color;

 // Text colors
$text-color-default: $brand-color-white;
$text-color-highlight: $brand-color-orange;
$text-color-highlight-alternative: $brand-color-highlight-alternative;
$text-color-inverted: $brand-color-black;

 // Link colors
$link-color-default: $brand-color-highlight;
$link-color-hover: lighten($link-color-default, 10%);
$link-color-active: lighten($link-color-default, 20%);

// General colors
$background-color-default: $brand-color-light;
$background-color-alternative: $brand-color-light-alternative;
$background-color-highlight: $brand-color-highlight;
$background-color-highlight-alternative: $brand-color-highlight-alternative;
$background-color-black: $brand-color-black;



/***
 *
 * Mixins
 *
 ***/

 @mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
 }



/***
 *
 * Import modules
 *
 ***/

@import '../_modules/top-menu/top-menu';
@import '../_modules/breadcrumbs/breadcrumbs';
@import '../_modules/section/section';
@import '../_modules/hover-box/hover-box';
@import '../_modules/icon-list/icon-list';


// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
    box-sizing: border-box;
}

html,body {
    height:100%;
}

body {
    //padding-top: $top-menu-height + 20;
}


// General typography

body {
    font-family:'Andes W04', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: $text-color-default;
}

h1, h2 {
    font-family: 'Source Code Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    color: $text-color-highlight;
}

.text-highlight {
    color: $text-color-highlight;
}

.text-highlight-alternative {
    color: $text-color-highlight-alternative;
}

p {
    font-size: 24px;
    margin: 0px;
}

a {
    color: $link-color-default;
    text-decoration: none;

    transition: color 0.1s ease-out;

    &:hover, &:focus {
        color: $link-color-hover;
        text-decoration: none;
    }

    &:active {
        color: $link-color-active;
        text-decoration: none;
    }
}

.outlineButton{
    border-style: solid;
    border-width: 1px;
    border-colour: white;
    max-width: 200px;
    padding-top: 10px;
    padding-bottom: 8px;
    text-align: center;
}

.breakline{
    border: 0;
    border-top: 1px solid $brand-color-dark;
    margin: 0px;
}
