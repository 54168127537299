// breadcrumbs styles
.breadcrumbs-section {
    background-color: $background-color-black;
    padding-top: 12px;
    padding-bottom: 12px;

    .breadcrumbs-wrap {
        max-width: $section-wrap-max-width;
        margin: 0 auto;

        ul.breadcrumbs {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                float: left;

                .breadcrumb-item, .breadcrumb-item:hover, .breadcrumb-item:focus, .breadcrumb-item:active {
                    color: $brand-color-white;
                }
                .breadcrumb-item:hover {
                    color: $menu-text-color-hover;
                }
                .noHome{
                    color: $brand-color-orange;
                }

                .noHome:before {
                    content: ">";
                    margin: 0 0.8em;
                    color: $brand-color-white;
                }
            }
        }
    }
}
