// icon-list styles
ul.icon-list {
    list-style: none;
    text-align: left;

    padding: 0;

    .title {
        color: $text-color-highlight;
    }
}
